import bobLogo from "../media/bob_logo_color_white.svg";
import bobLoadingImg from "../media/bob_loading.svg";
import iconHouse from "../media/Icon-House.svg";
import iconChart from "../media/Icon-Chart.svg";
import iconCalendar from "../media/Icon-Cal.svg";


const FrontPage = () => <div className="page-container">
    <div className="page-bg"></div>
    <div className="container">

        <div className="header">
            <div className="row">
                <div className="col-2">
                    <div className="logo">
                        <img src={bobLogo} alt="BOB Logo" />
                    </div>
                </div>
                <div className="col"></div>
            </div>
        </div>

        <div className="content">

            <div className="row">
                <div className="col"></div>
                <div className="col-4">
                    <div className="bob-loading">
                        <img src={bobLoadingImg} alt="BOB loading" />
                        <div className="loading-bar"></div>
                        <div className="loading-title">
                            prototype loading
                        </div>
                    </div>
                </div>
                <div className="col"></div>
            </div>

            <div class="row">
                <div class="col-8 offset-2 col-sm-12 offset-sm-0">

                    <div className="card">
                        <div className="row without-padding">
                            <div className="col-12 col-sm-4 hilite-section">
                                <div className="icon">
                                    <img src={iconHouse} alt="Icon - Bauen" />
                                </div>
                                <h1>Bauen</h1>
                                <p>Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. </p>
                            </div>

                            <div className="col-12 col-sm-4 hilite-section">
                                <div className="icon">
                                    <img src={iconChart} alt="Icon - Ordnen" />
                                </div>
                                <h1>Ordnen</h1>
                                <p>Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. </p>
                            </div>

                            <div className="col-12 col-sm-4 hilite-section">
                                <div className="icon">
                                    <img src={iconCalendar} alt="Icon - Bewirtschaften" />
                                </div>
                                <h1>Bewirtschaften</h1>
                                <p>Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. </p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>


            <div className="footer">
                <div className="copyright">
                    © Copyright 2020 by <a href="http://www.semptis.ch">Semptis GmbH</a> &amp; <a href="https://www.odddots.ch">Odddots GmbH</a> – All rights reserved
                    | <a href="#disclaimer">Disclaimer</a>
                </div>
            </div>

        </div>
    </div>
</div>;

export default FrontPage;